import styled from 'styled-components';
import classnames from 'classnames';

const LeadFormField = styled.div.attrs(props => ({
  className: classnames('form-group', props.className),
}))`
  margin-bottom: 0.5rem;
  label {
    color: #d9d9d9;
  }
  input {
    color: #d9d9d9;
    background-color: #101010;
    border: 1px solid #101010;
    border-bottom: 1px solid #989898;
    &:focus {
      background-color: #101010;
      color: #d9d9d9;
    }
  }
`;

export default LeadFormField;
