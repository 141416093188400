import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = styled.button.attrs(props => ({
  className: 'btn w-100 p-2',
  disabled: props.disabled,
  type: 'submit',
}))`
  background-color: #6c757d;
  color: #fff;
  margin-top: 1rem;
  text-transform: capitalize;
  border-radius: 0.5rem;
  font-family: Nunito, sans-serif;
  font-weight: 800;
  font-size: 1.2rem;
  @media (max-width: ${props => props.theme.mdWidth}) {
    padding: 1rem !important;
  }

  &:disabled {
    &:hover {
      cursor: not-allowed;
      color: #fff;
    }
  }

  &:not(disabled) {
    background-color: #ac0000;

    &:hover {
      background-color: #850000;
      cursor: pointer;
      color: #fff;
    }
  }
  ${props => props.theme.noSelect};
`;

const SubmitButton = ({ onClick, disabled, submitting, children }) => {
  const {
    homePageContent: { formCtaButtonLabel },
  } = useStaticQuery(
    graphql`
      query SubmitButton {
        homePageContent {
          formCtaButtonLabel
        }
      }
    `,
  );

  return (
    <Button onClick={onClick} disabled={disabled || submitting}>
      {submitting ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : formCtaButtonLabel}
    </Button>
  );
};

SubmitButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  submitting: false,
};

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default SubmitButton;
