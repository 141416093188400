import React from 'react';
import styled from 'styled-components';

const InstagramImage = styled.img`
  max-width: 100%;
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

const InstagramImg = props => {
  let imageSrc = props.post.mediaUrl;
  if (props.post.mediaType === 'VIDEO') {
    imageSrc = props.post.thumbnailUrl;
  }

  return (
    <a href={props.post.url} target="_blank" rel="noopener noreferrer">
      <InstagramImage src={imageSrc} />
    </a>
  );
};

export default InstagramImg;
