import styled from 'styled-components';
import { rem } from 'polished';

const PanelLeadFormContainer = styled.div`
  transform: scale(0.95);
  border-radius: 0.5rem;
  background-color: rgba(10, 10, 10, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -10rem 0;
  padding: 2rem 2rem;
  max-width: 30rem;
  -webkit-filter: drop-shadow(0 0 ${rem('5px')} #222);
  -ms-filter: dropShadow(0 0 ${rem('5px')} #222);
  filter: drop-shadow(0 0 ${rem('5px')} #222);
  @media (max-width: ${props => props.theme.lgWidth}) {
    display: none;
  }
  min-height: 37.3rem;
`;

export default PanelLeadFormContainer;
