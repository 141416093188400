import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = styled.button`
  background-color: ${props => props.buttonColor} !important;
  color: #fff !important;
`;

Button.defaultProps = {
  buttonColor: '#ac0000',
  className: 'btn',
  onClick: () => {},
};

Button.propTypes = {
  buttonColor: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const BackButton = ({ onClick }) => (
  <Button onClick={onClick}>
    <FontAwesomeIcon icon={faCaretLeft} /> Back
  </Button>
);

export default BackButton;
