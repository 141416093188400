import React from 'react';
import { navigate } from 'gatsby';
import { scroller } from 'react-scroll';
import CoreLayout from '../layouts/CoreLayout';
import SEO from '../components/SEO';
import HomePanel from '../components/HomePanel';
import LeadFormPanel from '../components/LeadFormPanel';
import MissionPanel from '../components/MissionPanel';
import InstagramPanel from '../components/InstagramPanel';
import './index.scss';

const IndexPage = props => {
  React.useEffect(() => {
    const { location } = props;
    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    if (location.state && location.state.toLeadPanel) {
      navigate('/', {
        state: {
          toLeadPanel: false,
        },
      });
      if (width <= 992) {
        scroller.scrollTo('leadFormPanel', {
          smooth: true,
          duration: 500,
        });
        setTimeout(() => {
          document.getElementById('mobileEventType').select();
        }, 600);
      } else {
        document.getElementById('desktopEventType').select();
      }
    }
  }, [props]);

  return (
    <CoreLayout location={props.location}>
      <SEO title="Home" keywords={['lasers', 'rave', 'light show']} />
      <HomePanel />
      <LeadFormPanel />
      <MissionPanel />
      <InstagramPanel />
    </CoreLayout>
  );
};

export default IndexPage;
