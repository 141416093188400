import React from 'react';
import gql from 'graphql-tag';
import NormalPanel from '../NormalPanel';
import InstagramPost from './InstagramPost';
import { graphQLApi } from '../../lib/api';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import LoadingSpinner from '../LoadingSpinner';

const PanelHeader = styled.div`
  color: #fff;
  text-align: center;
  margin: 0.5rem auto;
  width: 100%;
`;

const PanelHeaderText = styled.div`
  padding-bottom: 0.5rem;
  color: #fff;
`;

const InstagramLogo = styled(FontAwesomeIcon).attrs({
  icon: faInstagram,
  size: '3x',
})`
  padding: 0.5rem;
  margin: 0 auto;
  max-width: 0.875em;
`;

const InstagramLink = styled.a`
  margin: 0.8rem auto;
  color: #fff;
  display: block;
  padding: 0.5rem;
  font-size: 1.2rem;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
`;

const InstagramUsername = styled.span`
  font-size: 1.8rem;
  // ${InstagramLink}:hover & {
  //   border-bottom: 0.1rem solid #fff;
  // }
  ${InstagramLogo}:hover & {
    border-bottom: 0.1rem solid #fff;
  }
`;

const InstagramPanel = () => {
  const [posts, setPosts] = React.useState([]);

  React.useEffect(() => {
    graphQLApi()
      .query({
        query: gql`
          query Instagram {
            posts: instagramPosts(input: { limit: 6 }) {
              id
              mediaUrl
              mediaType
              likeCount
              commentsCount
              timestamp {
                format
              }
              url
              thumbnailUrl
            }
          }
        `,
      })
      .then(response => response.data.posts)
      .then(setPosts);
  }, []);

  const { socialMedia } = useStaticQuery(
    graphql`
      query {
        socialMedia: socialMediaAccounts {
          instagram: instagramUsername
        }
      }
    `,
  );

  let renderedPosts = <LoadingSpinner />;
  if (posts.length > 0) {
    renderedPosts = posts.map((post, key) => {
      return <InstagramPost key={key} post={post} />;
    });
  }

  return (
    <NormalPanel>
      <div className="col-12 offset-sm-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mt-1">
        <div className="row text-center">
          <InstagramLink href={`https://instagram.com/${socialMedia.instagram}`} target="_blank">
            <PanelHeader>
              <InstagramLogo />
              <PanelHeaderText>
                Follow us on Instagram
                <br />
                <InstagramUsername>@{socialMedia.instagram}</InstagramUsername>
              </PanelHeaderText>
            </PanelHeader>
          </InstagramLink>
        </div>
        <div className="row">{renderedPosts}</div>
      </div>
    </NormalPanel>
  );
};

export default styled(InstagramPanel)`
  padding-bottom: 15rem;
`;
