import styled from 'styled-components';

const InstagramPostContainer = styled.div.attrs({
  className: 'col-6 col-md-4 p-0 p-0 pl-md-0 pr-md-0',
})`
  transition: opacity 0.5s, transform 0.5s;
  position: relative;
  overflow: hidden;
  width: 50%;
  @media (min-width: 768px) {
    border: 10px solid #101010;
  }
  box-sizing: border-box;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export default InstagramPostContainer;
