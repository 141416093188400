import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import MoreButtons from './MoreButtons';
import PlayButton from './PlayButton';

const Video = styled.div`
  position: absolute;
  overflow: hidden;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: ${props => props.theme.mdWidth}) {
    background-image: url(${props => props.video.thumbnailUrl});
    background-position: center center;
    background-size: cover;
  }
  &:hover {
    cursor: pointer;
    ${PlayButton.container} {
      transform: scale(1.05);
    }
    ${PlayButton.icon} {
      -webkit-filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.75));
      filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.75));
      opacity: 1;
    }
  }
`;

const InstagramVideo = props => {
  const video = React.createRef();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isMuted, setMutedStatus] = React.useState(true);

  const togglePlayback = () => {
    if (!isMobile) {
      const v = video.current;
      if (v.paused) v.play();
      else v.pause();
    }
  };

  const toggleMute = () => {
    const v = video.current;
    v.muted = !v.muted;
  };

  React.useEffect(() => {
    video.current.addEventListener('play', () => {
      setIsPlaying(true);
    });
    video.current.addEventListener('pause', () => {
      setIsPlaying(false);
    });
    video.current.addEventListener('volumechange', event => {
      setMutedStatus(event.target.muted);
    });
  }, []);

  const audioButton = (
    <MoreButtons
      toggleMute={toggleMute}
      isMuted={isMuted}
      videoOpen={() => {
        const win = window.open(props.video.url, '_blank');
        win.focus();
      }}
      toggleVideo={togglePlayback}
    />
  );

  return (
    <Video video={props.video}>
      <video muted ref={video} onClick={togglePlayback}>
        <source src={props.video.mediaUrl} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <PlayButton onClick={togglePlayback} isPaused={!isPlaying} />
      {isPlaying ? audioButton : null}
    </Video>
  );
};

export default InstagramVideo;
