import PropTypes from 'prop-types';
import styled from 'styled-components';

const QuoteText = styled.h2`
  transition: color 2s ease-in-out 0s;
  width: 100%;
  max-width: 50rem;
  text-align: right;
  font-family: Exo, sans-serif;
  text-transform: uppercase;
  line-height: 0.9;
  font-weight: 800;
  color: ${props => props.textColor};
  font-size: 2.5rem;
  text-shadow: 0 0 18px rgba(0, 0, 0, 0.6);
  @media (min-width: ${props => props.theme.xlWidth}) {
    font-size: 4rem;
  }
  @media (max-width: 1349px) {
    font-size: 3.5rem;
  }
  @media (max-width: ${props => props.theme.lgWidth}) {
    text-align: center;
    max-width: 100%;
  }
  @media (max-width: ${props => props.theme.smWidth}) {
    text-align: right;
    font-size: 2.5rem;
  }
  @media (orientation: landscape) and (max-device-width: ${props => props.theme.lgWidth}) {
    text-align: right;
    transform: scale(0.8);
  }
`;

QuoteText.defaultProps = {
  textColor: 'rgba(243, 243, 243, 0.9)',
};

QuoteText.propTypes = {
  textColor: PropTypes.string.isRequired,
};

export default QuoteText;
