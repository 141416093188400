import styled from 'styled-components';

const ButtonRow = styled.div`
  .col-6 {
    padding: 0;
  }
  @media (orientation: landscape) and (max-device-width: ${props => props.theme.lgWidth}) {
    transform: translateY(1rem);
  }
`;

export default ButtonRow;
