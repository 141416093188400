import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

const LoadingSpinner = styled(FontAwesomeIcon).attrs({
  icon: faSpinnerThird,
  spin: true,
  size: '3x',
})`
  color: #fff;
  margin: 3rem auto;
`;

export default LoadingSpinner;
