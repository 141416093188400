import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeSlash } from '@fortawesome/pro-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Container = styled.div.attrs(({ onClick }) => ({ onClick }))`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
`;

const MoreButtonsContainer = styled.div`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${Container}:hover & {
    opacity: 1;
  }
  transition: opacity 0.2s ease-in-out;
`;

const VolumeIcon = styled(FontAwesomeIcon).attrs(({ isMuted, onClick }) => ({
  icon: isMuted ? faVolumeSlash : faVolumeUp,
  size: '2x',
  onClick,
}))`
  position: absolute;
  opacity: 0.6;
  top: 50%;
  left: calc(50% - 24px);
  transform: translate(-50%, -50%);
  color: #fff;
  margin: 0 auto;
  -webkit-filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.75));
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.75));
  transition: filter 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const OpenIcon = styled(FontAwesomeIcon).attrs(({ onClick }) => ({
  icon: faInstagram,
  size: '2x',
  onClick,
}))`
  position: absolute;
  opacity: 0.6;
  top: 50%;
  left: calc(50% + 24px);
  transform: translate(-50%, -50%);
  color: #fff;
  margin: 0 auto;
  -webkit-filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.75));
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.75));
  transition: filter 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const MoreButtons = ({ toggleMute, isMuted, videoOpen, toggleVideo }) => (
  <Container onClick={toggleVideo}>
    <MoreButtonsContainer>
      <VolumeIcon
        isMuted={isMuted}
        onClick={e => {
          e.stopPropagation();
          toggleMute(e);
        }}
      />
      <OpenIcon
        onClick={e => {
          e.stopPropagation();
          videoOpen(e);
        }}
      />
    </MoreButtonsContainer>
  </Container>
);

MoreButtons.container = MoreButtonsContainer;
MoreButtons.volumeIcon = VolumeIcon;
MoreButtons.openIcon = OpenIcon;

MoreButtons.defaultProps = {
  onClick: () => {},
  isMuted: true,
  videoOpen: () => {},
};
MoreButtons.propTypes = {
  onClick: PropTypes.func,
  isMuted: PropTypes.bool,
  videoOpen: PropTypes.func,
};

export default MoreButtons;
