import React from 'react';
import PropTypes from 'prop-types';
import { format } from '@buttercup/react-formatted-input';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import classnames from 'classnames';
import { Formik } from 'formik';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha'
import StyledForm from './StyledForm';
import LeadFormField from './LeadFormField';
import SubmitButton from './SubmitButton';
import { restApi } from '../../lib/api';

const RECAPTCHA_SITE_KEY = '6Lc8OtAZAAAAACMVKVo2eZ4xBBVJKWISHOnWuweS';
const PLACEHOLDER_EVENT_TYPE = 'Wedding, Concert, etc...';
const PLACEHOLDER_LOCATION = 'City and State...';
const PLACEHOLDER_FULL_NAME = 'Full name...';
const PLACEHOLDER_PHONE_NUMBER = '(800) 555-5555...';
const PLACEHOLDER_EMAIL_ADDRESS = 'For contacting you!';
const PLACEHOLDER_ADDITIONAL_DETAILS = 'Anything else we should know?';

const Header = styled.h4`
  color: white;
  font-family: Nunito, sans-serif;
  margin: 0 0 0.5rem;
`;

const USPhoneNumberFormat = [
  { exactly: '(' },
  { char: /\d/, repeat: 3 },
  { exactly: ')' },
  { exactly: ' ' },
  { char: /\d/, repeat: 3 },
  { exactly: '-' },
  { char: /\d/, repeat: 4 },
];

const Schema = yup.object().shape({
  eventType: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  location: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too long!')
    .required('Required'),
  fullName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phoneNumber: yup
    .string()
    .min(14, 'Too Short!')
    .max(14, 'Too Long!')
    .required('Required'),
  emailAddress: yup
    .string()
    .email('Invalid E-mail Address')
    .required('Required'),
  additionalDetails: yup.string(),
});

const errorText = (errors, touched, field) => {
  if (touched[field] && errors[field]) {
    return <small className="text-danger float-right">{errors[field]}</small>;
  }
  return null;
};

const LeadForm = ({ isCompleted, isErrored, isMobile }) => {
  const recaptchaRef = React.useRef();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await recaptchaRef.current.executeAsync();
    try {
      const response = await restApi().post('/mail/lead', values);
      isCompleted(response.data.referenceNumber);
      setSubmitting(false);
    } catch (err) {
      console.error(err);
      isErrored();
      setSubmitting(false);
    }
  };

  return (
    <StaticQuery
      query={graphql`
        query LeadFormContent {
          homePageContent {
            formCtaText
            formCtaButtonLabel
          }
        }
      `}
      render={({ homePageContent }) => (
        <Formik
          initialValues={{
            eventType: '',
            location: '',
            fullName: '',
            phoneNumber: '',
            emailAddress: '',
            additionalDetails: '',
          }}
          validationSchema={Schema}
          onSubmit={handleSubmit}>
          {({ errors, touched, values, handleChange, handleBlur, dirty, isValid, setFieldValue, isSubmitting }) => (
            <StyledForm>
              <div className="row">
                <div className="col-12">
                  <Header>{homePageContent.formCtaText}</Header>
                  <LeadFormField>
                    <label htmlFor="eventType">Event Type</label>
                    {errorText(errors, touched, 'eventType')}
                    <input
                      name="eventType"
                      id={isMobile ? 'mobileEventType' : 'desktopEventType'}
                      type="text"
                      value={values.eventType}
                      onChange={handleChange}
                      className={classnames('form-control', {
                        'is-valid': touched.eventType && !errors.eventType,
                        'is-invalid': touched.eventType && errors.eventType,
                      })}
                      placeholder={PLACEHOLDER_EVENT_TYPE}
                      onBlur={handleBlur}
                    />
                  </LeadFormField>
                </div>
                <div className="col-12">
                  <LeadFormField>
                    <label htmlFor="location">Where</label>
                    {errorText(errors, touched, 'location')}
                    <input
                      name="location"
                      type="text"
                      value={values.location}
                      onChange={handleChange}
                      className={classnames('form-control', {
                        'is-valid': touched.location && !errors.location,
                        'is-invalid': touched.location && errors.location,
                      })}
                      placeholder={PLACEHOLDER_LOCATION}
                      onBlur={handleBlur}
                    />
                  </LeadFormField>
                </div>
                <div className="col-6">
                  <LeadFormField>
                    <label htmlFor="fullName">Full Name</label>
                    {errorText(errors, touched, 'fullName')}
                    <input
                      name="fullName"
                      type="text"
                      value={values.fullName}
                      onChange={handleChange}
                      className={classnames('form-control', {
                        'is-valid': touched.fullName && !errors.fullName,
                        'is-invalid': touched.fullName && errors.fullName,
                      })}
                      placeholder={PLACEHOLDER_FULL_NAME}
                      onBlur={handleBlur}
                    />
                  </LeadFormField>
                </div>
                <div className="col-6">
                  <LeadFormField>
                    <label htmlFor="phoneNumber">Phone</label>
                    {errorText(errors, touched, 'phoneNumber')}
                    <input
                      name="phoneNumber"
                      type="text"
                      value={values.phoneNumber}
                      onChange={event =>
                        setFieldValue('phoneNumber', format(event.target.value || '', USPhoneNumberFormat).formatted)
                      }
                      className={classnames('form-control', {
                        'is-valid': touched.phoneNumber && !errors.phoneNumber,
                        'is-invalid': touched.phoneNumber && errors.phoneNumber,
                      })}
                      placeholder={PLACEHOLDER_PHONE_NUMBER}
                      onBlur={handleBlur}
                    />
                  </LeadFormField>
                </div>
                <div className="col-12">
                  <LeadFormField>
                    <label htmlFor="emailAddress">E-Mail</label>
                    {errorText(errors, touched, 'emailAddress')}
                    <input
                      name="emailAddress"
                      type="email"
                      value={values.emailAddress}
                      onChange={handleChange}
                      className={classnames('form-control', {
                        'is-valid': touched.emailAddress && !errors.emailAddress,
                        'is-invalid': touched.emailAddress && errors.emailAddress,
                      })}
                      placeholder={PLACEHOLDER_EMAIL_ADDRESS}
                      onBlur={handleBlur}
                    />
                  </LeadFormField>
                </div>
                <div className="col-12">
                  <LeadFormField>
                    <label htmlFor="additionalDetails">Additional Details</label>
                    {errorText(errors, touched, 'additionalDetails')}
                    <input
                      name="additionalDetails"
                      type="text"
                      value={values.additionalDetails}
                      onChange={handleChange}
                      className={classnames('form-control', {
                        'is-valid': touched.additionalDetails && !errors.additionalDetails,
                        'is-invalid': touched.additionalDetails && errors.additionalDetails,
                      })}
                      placeholder={PLACEHOLDER_ADDITIONAL_DETAILS}
                      onBlur={handleBlur}
                    />
                  </LeadFormField>
                </div>
                <div className="col-12">
                  <SubmitButton disabled={!dirty || !isValid} submitting={isSubmitting} />
                  <RecaptchaContainer>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_SITE_KEY}
                      size="invisible"
                      theme="dark"
                    />
                  </RecaptchaContainer>
                </div>
              </div>
            </StyledForm>
          )}
        </Formik>
      )}
    />
  );
};

LeadForm.propTypes = {
  isCompleted: PropTypes.func.isRequired,
  isErrored: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

LeadForm.defaultProps = {
  isMobile: false,
};

const RecaptchaContainer = styled.div`
  display: none;
`

export default LeadForm;
