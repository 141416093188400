import styled from 'styled-components';

const AbstractChildren = styled.div`
  z-index: 4;
  position: relative;
  width: 100%;
  height: 100%;
  -ms-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
`;

export default AbstractChildren;
