import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import classnames from 'classnames';

const Image = styled(Img)`
  position: absolute !important;
  min-width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  transition: opacity 2s ease-in-out 0s !important;
  opacity: 0;
  z-index: 2;
  &.current {
    opacity: 1;
    z-index: 3;
  }
`;

const PanelCaption = styled.div`
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 2rem;
  display: block;
  transition: opacity 2s ease-in-out 0s;
  z-index: 4;
  opacity: 0;
  &.current {
    opacity: 1;
    z-index: 4;
  }
  color: ${props => props.textColor};
  p {
    line-height: 0.5rem;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
    &.mobile {
      display: none;
    }
  }
  @media (max-width: ${props => props.theme.mdWidth}),
    (orientation: landscape) and (max-width: ${props => props.theme.lgWidth}) {
    bottom: 4rem;
    text-align: center;
    font-size: 0.8rem;
    p {
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
      line-height: 0.2rem;
      &.mobile {
        display: none
      }
    }
    @media (max-width: ${props => props.theme.mdWidth}) {
      padding: 0 6.2rem;
      margin: 0;
      width: 100%;
      p {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
          line-height: 1rem;
        }
      }
    }
    @media (orientation: landscape) and (max-width: ${props => props.theme.lgWidth}) {
      width: 100%;
      margin: 0;
      bottom: 0;
      p {
        &.lineHeight {
          line-height: 1rem;
        }
        &.mobile {
          display: none;
        }
      }
    }
  }
`;

const BackgroundImage = props => {
  const { image, artist, event, location, textColor, isCurrentImage } = props;

  const hasArtistInfo = artist !== null && event !== null && location !== null;

  const classes = classnames({
    current: isCurrentImage,
  });

  let imageAlt = 'Background Image';
  if (hasArtistInfo) imageAlt = `${artist} / ${event} / ${location}`;

  return [
    <Image
      key={0}
      className={classes}
      fluid={image.childImageSharp.fluid}
      objectPosition="bottom center"
      objectFit="cover"
      alt={imageAlt}
    />,
    <PanelCaption key={1} className={classes} textColor={textColor}>
      <p className="mobile lineHeight">{artist}</p>
      <p className="mobile lineHeight">{event}</p>
      <p className="mobile">{location}</p>
      <p className="lineHeight desktop">{artist} / {event}</p>
      <p className="desktop">{location}</p>
    </PanelCaption>,
  ];
};

BackgroundImage.defaultProps = {
  isCurrentImage: false,
  artist: null,
  event: null,
  location: null,
};

BackgroundImage.propTypes = {
  currentImage: PropTypes.bool,
  image: PropTypes.any.isRequired,
  artist: PropTypes.string,
  event: PropTypes.string,
  location: PropTypes.string,
};

export default BackgroundImage;
