import React from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { scroller } from 'react-scroll';
import AbstractPanel, { ThemeContext } from '../AbstractPanel';
import LeadForm from '../LeadForm';
import QuoteText from './QuoteText';
import PanelLeadFormContainer from './PanelLeadFormContainer';
import FirstPanel from './FirstPanel';
import BookButton from './BookButton';
import CTA from './CTA';
import ButtonRow from './ButtonRow';
import Button from './Button';
import Box from './Box';

const HomePanel = () => (
  <StaticQuery
    query={graphql`
      query FirstPanelContent {
        homePageContent {
          homeSlogan
        }
        images: allHomePageBackgroundImage {
          edges {
            node {
              artist
              event
              location
              typographyColor
              imageUrl
              name
              image {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { homeSlogan } = data.homePageContent;
      const { edges } = data.images;
      const images = edges.map(edge => ({
        ...edge.node,
        typographyColor: (node => {
          const rgba = JSON.parse(node.typographyColor);
          const { r, g, b, a } = rgba;
          return `rgba(${r},${g},${b},${a})`;
        })(edge.node),
      }));

      return (
        <AbstractPanel images={images}>
          <FirstPanel>
            <Box className="row">
              <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
                <ThemeContext.Consumer>
                  {({ typographyColor }) => <QuoteText textColor={typographyColor}>{homeSlogan}</QuoteText>}
                </ThemeContext.Consumer>
              </div>
              <CTA className="d-block d-lg-none col-12">
                <ButtonRow className="row">
                  <div className="col-6">
                    <BookButton
                      className="book-button"
                      type="button"
                      onClick={() =>{
                        scroller.scrollTo('leadFormPanel', {
                          smooth: true,
                          duration: 500,
                        });
                        document.getElementById('mobileEventType').select();
                      }}>
                      Book Now
                    </BookButton>
                  </div>
                  <div className="col-6">
                    <Button type="button" onClick={() => navigate('/about')}>
                      About Us
                    </Button>
                  </div>
                </ButtonRow>
              </CTA>
              <div className="col-12 col-lg-6">
                <PanelLeadFormContainer>
                  <LeadForm />
                </PanelLeadFormContainer>
              </div>
            </Box>
          </FirstPanel>
        </AbstractPanel>
      );
    }}
  />
);

export default HomePanel;
