import React from 'react';
import styled from 'styled-components';
import InstagramPostContainer from './InstagramPostContainer';
import InstagramVideo from './InstagramVideo';
import InstagramImg from './InstagramImg';

const Container = styled(InstagramPostContainer)`
  display: none;
  @media (min-width: ${props => props.theme.mdWidth}) {
    display: block;
  }
`;

const MobileContainer = styled(InstagramPostContainer).attrs(({ post }) => ({
  onClick: e => {
    e.stopPropagation();
    const win = window.open(post.url, '_blank');
    win.focus();
  },
}))`
  display: block;
  @media (min-width: ${props => props.theme.mdWidth}) {
    display: none;
  }
`;

const InstagramPost = ({ post }) => {
  if (['IMAGE', 'CAROUSEL_ALBUM', 'VIDEO'].includes(post.mediaType)) {
    return [
      <Container post={post} key={0}>
        <InstagramImg post={post} />
      </Container>,
      <MobileContainer post={post} key={1}>
        <InstagramImg post={post} />
      </MobileContainer>,
    ];
  }
  return null;
};

export default styled(InstagramPost)``;
