import React from 'react';
import PropTypes from 'prop-types';
import AbstractPanelContainer from './AbstractPanelContainer';
import AbstractPanelInnerContainer from './AbstractPanelInnerContainer';
import AbstractChildren from './AbstractChildren';
import BackgroundImage from './BackgroundImage';

const IMAGE_INTERVAL_SECONDS = 5;

export const ThemeContext = React.createContext({ typographyColor: 'white' });

const AbstractPanel = ({ images, children }) => {
  const [currentImage, setCurrentImage] = React.useState(0);

  React.useEffect(() => {
    if (images.length > 1) {
      const timeout = setTimeout(() => {
        let newImage = currentImage + 1;
        if (newImage > images.length - 1) {
          newImage = 0;
        }
        setCurrentImage(newImage);
      }, IMAGE_INTERVAL_SECONDS * 1000);
      return () => clearTimeout(timeout);
    }
  }, [currentImage]);

  const themeContext = {
    typographyColor: images[currentImage].typographyColor,
  };

  return (
    <AbstractPanelContainer>
      <AbstractPanelInnerContainer>
        {images.map((image, key) => (
          <BackgroundImage
            key={key}
            image={image.image}
            isCurrentImage={key === currentImage}
            artist={image.artist}
            event={image.event}
            location={image.location}
            textColor={image.typographyColor}
          />
        ))}
        <AbstractChildren>
          <ThemeContext.Provider value={themeContext}>{children}</ThemeContext.Provider>
        </AbstractChildren>
      </AbstractPanelInnerContainer>
    </AbstractPanelContainer>
  );
};

AbstractPanel.propTypes = {
  images: PropTypes.array.isRequired,
};

export default AbstractPanel;
