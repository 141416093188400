import styled from 'styled-components';
import { noSelect } from '../../styles/mixins';

const Button = styled.button`
  ${noSelect()};
  font-family: Oxygen, sans-serif;
  text-transform: uppercase;
  margin: 0.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.95);
  }
`;

export default Button;
