import styled from 'styled-components';

const FirstPanel = styled.div`
  padding: 0.5rem;
  top: 40%;
  position: relative;
  width: 100%;
`;

export default FirstPanel;
