import React from 'react';
import NormalPanel from '../NormalPanel';
import styled from 'styled-components';
import { FacebookProvider, EmbeddedVideo } from 'react-facebook';
import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/fontawesome-pro-solid'

const FacebookContainer = styled.div`
  -webkit-box-shadow: 0px 0px 58px 6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 58px 6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 58px 6px rgba(0, 0, 0, 0.75);
  @media (orientation: landscape) and (max-device-width: ${props => props.theme.lgWidth}) {
    display: none;
  }
`;

const Text = styled.h2`
  color: #fff;
  padding: 0.5rem;
  text-align: left;
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
  @media (max-width: ${props => props.theme.xlWidth}) {
    font-size: 1.5rem;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.lgWidth}) {
    font-size: 2rem;
  }
  @media (max-width: ${props => props.theme.mdWidth}) {
    font-size: 2rem;
  }
  @media (max-width: ${props => props.theme.smWidth}) {
    font-size: 1.5rem;
  }
`;

const Paragraph = styled.p`
  color: #fff;
  padding: 0 0.5rem;
  text-align: left;
  font-size: 1.4rem;
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
  @media (max-width: ${props => props.theme.xlWidth}) {
    font-size: 1.5rem;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.lgWidth}) {
    font-size: 1.5rem;
  }
  @media (max-width: ${props => props.theme.mdWidth}) {
    font-size: 2rem;
  }
  @media (max-width: ${props => props.theme.smWidth}) {
    font-size: 1.1rem;
  }
`;

const FacebookVideoPanel = styled.div`
  @media (max-width: ${props => props.theme.lgWidth}) {
    height: 50%;
  }
`;

const TextPanel = styled.div`
  @media (max-width: ${props => props.theme.lgWidth}) {
    align-items: flex-end;
    display: flex;
    height: 50%;
  }
`;

const Link = styled.p`
  display: none;
  @media (orientation: landscape) and (max-device-width: ${props => props.theme.lgWidth}) {
    display: block;
  }
  a {
    color: white;
    text-decoration: none;
    &:focus {
      color: white;
      text-decoration: none;
    }
    &:hover {
      color: white;
      text-decoration: none;
    }
  }
  font-size: 2rem;
  text-align: center;
  padding: 1rem;
  width: 100%;
`;

const MissionPanel = () => {
  const {
    socialMedia: { appId },
    facebookPanelInfo: { backgroundImage, facebookVideo, facebookVideoPanelTitle, facebookText },
  } = useStaticQuery(graphql`
    query {
      socialMedia: socialMediaAccounts {
        appId: facebookAppId
      }
      facebookPanelInfo {
        facebookVideo
        facebookText
        facebookVideoPanelTitle
        backgroundImageUrl
        backgroundImage {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);

  if (!facebookVideo) {
    return null
  }

  return (
    <NormalPanel backgroundImage={backgroundImage.childImageSharp.fluid}>
      <div className="row align-items-center m-0">
        <FacebookVideoPanel className="col-12 col-lg-6 order-lg-1 col-xxl-4 offset-xxl-2 order-2">
          <FacebookContainer>
            <FacebookProvider appId={appId}>
              <EmbeddedVideo href={facebookVideo} />
            </FacebookProvider>
          </FacebookContainer>
          <Link>
            <a href={facebookVideo} target="_blank"><FontAwesomeIcon icon={faPlayCircle} /> Play Video</a>
          </Link>
        </FacebookVideoPanel>
        <TextPanel className="col-12 col-lg-6 order-lg-2 col-xxl-4 order-1 order-xxl-2">
          <div>
            <Text>{facebookVideoPanelTitle}</Text>
            <Paragraph>{facebookText}</Paragraph>
          </div>
        </TextPanel>
      </div>
    </NormalPanel>
  );
};

export default MissionPanel;
