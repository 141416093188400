import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import BackButton from './BackButton';

const ThanksContainer = styled.div`
  color: #fff;
  height: 100%;
  width: 100%;
`;

const Header = styled.h2`
  text-align: center;
  width: 100%;
  font-family: 'Darker Grotesque', sans-serif;
`;

const Text = styled.p`
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  font-family: 'Darker Grotesque', sans-serif;
`;

const ThankYouFrame = ({ isFinished, referenceCode }) => {
  let { thankYouText, thankYouTitle } = useStaticQuery(
    graphql`
      query {
        homePageContent {
          thankYouTitle
          thankYouText
        }
      }
    `,
  );
  if (!thankYouText) {
    thankYouText = 'You will be contacted in 1-2 business days!';
  }
  if (!thankYouTitle) {
    thankYouTitle = 'Thank You!';
  }

  return (
    <ThanksContainer>
      <Header>{thankYouTitle}</Header>
      <Text>{thankYouText}</Text>
      <Text>
        <b>Reference #{referenceCode}</b>
      </Text>
      <BackButton onClick={isFinished} />
    </ThanksContainer>
  );
};

ThankYouFrame.defaultProps = {
  referenceCode: '',
};

ThankYouFrame.propTypes = {
  isFinished: PropTypes.func.isRequired,
  referenceCode: PropTypes.string,
};

export default ThankYouFrame;
