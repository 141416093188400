import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import { graphql, useStaticQuery } from 'gatsby';

const ErrorFrameContainer = styled.div`
  color: #fff;
  height: 100%;
  width: 100%;
`;

const Header = styled.h2`
  text-align: center;
  width: 100%;
  font-family: 'Darker Grotesque', sans-serif;
`;

const Text = styled.p`
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  font-family: 'Darker Grotesque', sans-serif;
`;

const ErrorFrame = ({ isFinished }) => {
  let { errorText, errorTitle } = useStaticQuery(
    graphql`
      query {
        homePageContent {
          errorTitle
          errorText
        }
      }
    `,
  );
  return (
    <ErrorFrameContainer>
      <Header>{errorTitle}</Header>
      <Text>{errorText}</Text>
      <BackButton onClick={isFinished} />
    </ErrorFrameContainer>
  );
};

ErrorFrame.propTypes = {
  isFinished: PropTypes.func.isRequired,
};

export default ErrorFrame;
