import styled from 'styled-components';

const AbstractPanelInnerContainer = styled.div`
  position: relative;
  height: 100%;
  background-color: #101010;
  @media (orientation: landscape) and (max-device-width: ${props => props.theme.lgWidth}) {
    overflow: auto;
    height: 100vh;
  }
`;

export default AbstractPanelInnerContainer;
