import styled from 'styled-components';

const Box = styled.div`
  margin: 0 !important;
  transform: translateY(-25%);
  @media (min-width: ${props => props.theme.mdWidth}) {
    transform: translateY(-20%);
  }
  @media (min-width: ${props => props.theme.lgWidth}) {
    transform: translateY(0);
  }
`;

export default Box;
