import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';

const PlayButtonContainer = styled.div`
  opacity: ${props => (props.isPaused ? 1 : 0)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease-in-out;
`;

const PlayButtonIcon = styled(FontAwesomeIcon).attrs({
  icon: faPlay,
  size: '3x',
})`
  position: absolute;
  opacity: 0.8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  margin: 0 auto;
  -webkit-filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.75));
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.75));
  transition: filter 0.2s ease-in-out;
`;

const PlayButton = ({ onClick, isPaused }) => (
  <PlayButtonContainer onClick={onClick} isPaused={isPaused}>
    <PlayButtonIcon />
  </PlayButtonContainer>
);

PlayButton.container = PlayButtonContainer;
PlayButton.icon = PlayButtonIcon;

PlayButton.defaultProps = {
  onClick: () => {},
  isPaused: true,
};
PlayButton.propTypes = {
  onClick: PropTypes.func,
  isPaused: PropTypes.bool,
};

export default PlayButton;
