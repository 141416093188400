import axios from 'axios';
import ApolloClient from 'apollo-boost';

const { GATSBY_API_ENDPOINT } = process.env;

export const restApi = () =>
  axios.create({
    baseURL: `${GATSBY_API_ENDPOINT}/rest`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const graphQLApi = () =>
  new ApolloClient({
    uri: `${GATSBY_API_ENDPOINT}/gql`,
  });
