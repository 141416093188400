import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';
import LeadForm from '../LeadForm';
import NormalPanel from '../NormalPanel';
import MonochromeLogo from '../MonochromeLogo';

const LeadFormPanelDiv = styled.div`
  display: block;
  @media (min-width: ${props => props.theme.lgWidth}) {
    display: none;
  }
`;

const FormContainer = styled.div.attrs({
  className: 'col-sm-8 offset-sm-2 col-12',
})`
  padding: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
`;

const LeadFormPanel = () => {
  return (
    <Element name="leadFormPanel">
      <LeadFormPanelDiv>
        <NormalPanel>
          <FormContainer>
            <div className="row">
              <LeadForm scrollAfter />
              <MonochromeLogo />
            </div>
          </FormContainer>
        </NormalPanel>
      </LeadFormPanelDiv>
    </Element>
  );
};

export default LeadFormPanel;
