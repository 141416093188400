import styled from 'styled-components';
import { Form } from 'formik';
import { noSelect } from '../../styles/mixins';

const StyledForm = styled(Form)`
  @media (max-width: ${props => props.theme.mdWidth}) {
    padding: 1rem !important;
  }
  color: #000;
  text-align: left;
  label {
    font-family: Nunito, sans-serif;
    color: #fff;
    text-align: left;
  }
  button {
    ${noSelect()};
    background-color: #6c757d;
    color: #fff;
    margin-top: 1rem;
    text-transform: capitalize;
    border-radius: 0.5rem;
    font-family: Nunito, sans-serif;
    font-weight: 800;
    font-size: 1.2rem;
    @media (max-width: ${props => props.theme.mdWidth}) {
      padding: 1rem !important;
    }
    &:disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
    &:not(disabled) {
      background-color: #ac0000;
      &:hover {
        background-color: #850000;
        cursor: pointer;
      }
    }
  }
`;

export default StyledForm;
