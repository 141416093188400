import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LeadForm from './LeadForm';
import ThankYouFrame from './ThankYouFrame';
import ErrorFrame from './ErrorFrame';
import { scroller } from 'react-scroll';

const DivContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const LeadFormContainer = ({ scrollAfter }) => {
  const [step, setStep] = React.useState(0);
  const [referenceNum, setReferenceNum] = React.useState('');

  const isCompleted = referenceNumber => {
    setReferenceNum(referenceNumber);
    setStep(1);
    if (scrollAfter) {
      scroller.scrollTo('leadFormPanel');
    }
  };

  const isErrored = () => {
    setStep(2);
  };

  const isFinished = () => {
    setStep(0);
  };

  return (
    <DivContainer>
      {step === 0 ? <LeadForm isCompleted={isCompleted} isErrored={isErrored} isMobile={scrollAfter} /> : null}
      {step === 1 ? <ThankYouFrame isFinished={isFinished} referenceCode={referenceNum} /> : null}
      {step === 2 ? <ErrorFrame isFinished={isFinished} /> : null}
    </DivContainer>
  );
};

LeadFormContainer.defaultProps = {
  spacing: 1,
  scrollAfter: false,
};

LeadFormContainer.propTypes = {
  spacing: PropTypes.number,
  scrollAfter: PropTypes.bool,
};

export default LeadFormContainer;
