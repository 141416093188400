import styled from 'styled-components';
import { rem } from 'polished';

const AbstractPanelContainer = styled.div`
  height: 100vh;
  @media (min-device-width: ${props => props.theme.mdWidth}) {
    min-height: ${rem('800px')};
  }
  @media (orientation: landscape) and (max-device-width: ${props => props.theme.lgWidth}) {
    min-height: 60vh;
  }
`;

export default AbstractPanelContainer;
